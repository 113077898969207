
/**
 * No changes needed here
 */

 jQuery(function($) {

  /* initialize FlexNav */

    $(".flexnav").flexNav({
      'calcItemWidths': false,
      'hover': false
    });

    /* clone overview pages into submenu */

    $('.menu-item-has-children > a').each(function(index, el){

      if( $(this).parent().hasClass('append-to-sub') ) {

        var target = $(this).next('ul');
        var classes = $(this).parent().attr("class");
        var text = $(this).data("title");

        $(this).clone().prependTo(target).wrap('<li></li>').text(text).parent().attr("class", classes).removeClass('menu-item-has-children item-with-ul current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_parent current_page_ancestor append-to-sub');
        $(this).attr("href", "#");
      }else{
        $(this).attr("href", "#");
      }

      $(this).on('click', function(e) {
        e.preventDefault();
      });

    });

  /* initialize imageText slider */

  if ($('#sliderWrapper').length && $('#sliderWrapper').hasClass( "owlActive" )) {

    $("#sliderWrapper").owlCarousel({
      autoplay: true,
      items: 1,
      loop: true,
      smartSpeed: 400,
      dots: false,
      dotsSpeed: 300,
      responsive : {
        1025 : {
          dots: true,
        }
      }
    });

  }

  /* initialize testimonial slider */

  if ($('#testimonialSlidesWrapper').length && $('#testimonialSlidesWrapper').hasClass( "owlActive" )) {

    $("#testimonialSlidesWrapper").owlCarousel({
      autoplay: true,
      items: 1,
      loop: true,
      smartSpeed: 400,
      dots: false,
      dotsSpeed: 300,
      responsive : {
        0 : {
          nav: false,
        },
        768 : {
          nav: true,
          navText: ['',''],
          navContainer: '#slider-nav',
        }
      }
    });

  }

  /* initialize eventSlider */

  if ($('#eventSliderWrapper').length && $('#eventSliderWrapper').hasClass( "owlActive" )) {

    $("#eventSliderWrapper").owlCarousel({
      autoplay: true,
      items: 1,
      loop: true,
      smartSpeed: 400,
      dotsSpeed: 300,
      responsive : {
        0 : {
          nav: false,
          dots: false,
        },
        500 : {
          dots: true,
        },
        1300 : {
          nav: true,
          navText: ['',''],
          navContainer: '#slider-nav',
        },
      }
    });

  }

  /* initialize gallerySlider */

  if ($('.gallerySlidesWrapper').length && $('.gallerySlidesWrapper').hasClass( "owlActive" )) {

    $(".gallerySlidesWrapper").owlCarousel({
      autoplay: true,
      items: 3,
      margin: 20,
      loop: true,
      center: true,
      nav: false,
      navText: ['',''],
      navContainer: '#slider-nav',
      responsive : {
        0 : {
          nav: false,
          dots: true,
          items: 1,
        },
        500 : {
          items: 2,
          nav: false,
          dots: true,
        },
        800 : {
          items: 3,
          nav: false,
          dots: true,
        },
        1500 : {
          nav: true,
        },
      }
    });

  }

  /* fancy box remove hash links */
  $.fancybox.defaults.hash = false;

  /* enable select2 for select fields */

  $('.selectTwo').select2({
    minimumResultsForSearch: Infinity,
    width: "style"
  });

  /* scroll to Anfahrt */

  $('.directions').on('click', function() {
    $("html, body").animate({ scrollTop: $(".acf-map").offset().top }, 'slow', 'swing');
    return false;
  });

  /* filter speisekarte */

  $('.filter.none').hide();

  $('.filter.none').on('click', function() {
    $('.filter').removeClass('active');
    $(".foodMenu .menu, .foodMenu .cat").fadeIn();
    $(this).hide();
  });

  $('.filter.vegan').on('click', function() {
    $('.filter').removeClass('active');
    $(this).addClass('active');
    $(".foodMenu .menu, .foodMenu .cat").not('.vegan').fadeOut();
    $('.filter.none').show();
    if ( typeof ga === 'function' ) {
      ga('send', 'event', 'speisekarte', 'vegan', 'click');
    }
  });

  $('.filter.vegetarian').on('click', function() {
    $('.filter').removeClass('active');
    $(this).addClass('active');
    $(".foodMenu .menu.vegan, .foodMenu .cat.vegan").fadeIn();
    $(".foodMenu .menu.vegetarian, .foodMenu .cat.vegetarian").fadeIn();
    $(".foodMenu .menu, .foodMenu .cat").not('.vegetarian, .vegan').fadeOut();
    $('.filter.none').show();
    if ( typeof ga === 'function' ) {
      ga('send', 'event', 'speisekarte', 'vegetarisch', 'click');
    }
  });

  // Datepicker

  $.fn.datepicker.language['de'] = {
      days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      daysShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      months: ['Januar','Februar','März','April','Mai','Juni', 'Juli','August','September','Oktober','November','Dezember'],
      monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      today: 'Heute',
      clear: 'Aufräumen',
      dateFormat: 'dd.mm.yyyy',
      timeFormat: 'hh:ii',
      firstDay: 1
  };

  $('#datum').datepicker({
      language: 'de',
      minDate: new Date(),
      autoClose: true
  });


  document.addEventListener( 'wpcf7mailsent', function( event ) {
      if ( typeof ga === 'function' ) {
        if ( '1111' == event.detail.contactFormId ) {
          ga( 'send', 'event', 'contactFormSubmit', 'event' );
        }
        if ( '211' == event.detail.contactFormId ) {
          ga( 'send', 'event', 'contactFormSubmit', 'coupon' );
        }
        if ( '868' == event.detail.contactFormId ) {
          ga( 'send', 'event', 'contactFormSubmit', 'contact' );
        }
        if ( '209' == event.detail.contactFormId ) {
          ga( 'send', 'event', 'contactFormSubmit', 'reservation' );
        }
      }
  }, false );

  (function($){

      "use strict";

      var settings = {
          html            : '<div id="cookieBanner"><div id="cookieInner"><p></p></div></div>',
          opacity         : 0,
          position        : 'bottom',
          closeValue      : 'Okay',
          innerAttr       : {},
          buttonAttr      : {},
          wrapperAttr     : {},
          text            : {
              'de' : 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer <a href="/datenschutz/" id="notePrivacy" style="text-decoration: underline;">Datenschutzerklärung</a>.'
          }
      };
      var block;

      var privacy = {
          init: function() {
              if (privacy.readCookie('cookiesAccepted')) {
                  return;
              }
              if (settings.position == 'top') {
                  block = $(settings.html).prependTo('body');
              } else {
                  block = $(settings.html).appendTo('body');
              }
              $.each(settings.innerAttr, function(key, value) {
                  $('#cookieInner').attr(key, value);
              });
              $.each(settings.wrapperAttr, function(key, value) {
                  $('#cookieBanner').attr(key, value);
              });

              $('#cookieInner').find('p').html(settings.text[$('html').attr('lang')]);

              $('<a id="closeBanner">' + settings.closeValue +  '</a>').appendTo('#cookieInner p').on('click', function() {
                  $('#cookieBanner').slideUp(400, function() {
                      $(this).empty();
                      privacy.setCookie('cookiesAccepted', true, 730);
                  });
              });
              $.each(settings.buttonAttr, function(key, value) {
                  $('#closeBanner').attr(key, value);
              });
          },
          setCookie: function(name,value,days) {
              var expires = "";
              if (days) {
                  var date = new Date();
                  date.setTime(date.getTime() + (days*24*60*60*1000));
                  expires = "; expires=" + date.toUTCString();
              }
              document.cookie = name + "=" + date.getTime() + expires + "; path=/";
          },
          readCookie: function(name) {
              var nameEQ = name + "=";
              var ca = document.cookie.split(';');
              for(var i=0;i < ca.length;i++) {
                  var c = ca[i];
                  while (c.charAt(0)==' ') c = c.substring(1,c.length);
                  if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
              }
              return null;
          }
      }

      $.fn.privacy = function( options ) {

          settings = $.extend(settings, options);

          privacy.init();

          return this;
       };

  }( jQuery ));


  $('body').privacy();

  $('.mailster-form .submit-button').on('click',function(){
    var honeypot = $('input[name$="_email"]');
    //console.log(honeypot);
    //console.log('submitted');
    $(honeypot).val('');
  });

  /* overlay */

  var overlay = $('.overlay');
  var days = 30;
  var date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));

  //console.log(date);

  if (typeof cookieName !== 'undefined' && !$.cookie(cookieName) && overlay.length) {

      $.fancybox.open({
          src: '.overlay',
          type: 'inline',
          touch: false,
          baseClass: 'fancyboxOverlay',
          clickSlide : false,
          afterClose: function (instance, slide) {
              $.cookie(cookieName, true, {expires: date, path: '/'});
          }
      });
  }

    /* lang Switch */

    document.querySelector('.toggleLanguage').addEventListener('click', function() {
        // Suche alle Elemente mit den data-de und data-en Attributen
        var elementsWithData = document.querySelectorAll('[data-de][data-en]');

        // Gehe durch jedes Element und tausche den Text aus
        elementsWithData.forEach(function(element) {
            var lang = element.getAttribute('data-lang') === 'de' ? 'en' : 'de';

            var newText = element.getAttribute('data-' + lang);
            newText = JSON.parse('"' + newText + '"');
            element.textContent = newText;
            element.setAttribute('data-lang', lang);
        });
    });

});
