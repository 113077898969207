jQuery(function() {
  
  var $ = jQuery;
  $.ajaxSetup({
    cache: true
  });

  /* count element */

  function countEqual($this){

    // Enter num from and to
    $({countNum: 0}).animate({countNum: $this.data('count')}, {
      duration: 3000,
      easing:'linear',
      step: function() {
        // What todo on every count
        $this.html(parseInt(this.countNum));
      },
      complete: function() {
        // What todo at the end
        $this.html($this.data('count'));
      }
    });

  }

  $(".count span").each(function() {
      $(this).data('count', parseInt($(this).html(), 10));
      $(this).html('0');
  });

  /* animations */

    /* divider */

    if ($('.divide').length) {
      $('.divide').addClass('hide');
      hunt(document.querySelectorAll('.divide'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeIn-active');
        },
        offset: -100
      });
    }



});